<template>
  <v-form ref="form" lazy-validation>
    <v-dialog
      v-model="dialog"
      max-width="600px"
      persistent
      transition="dialog-top-transition"
      @keydown.esc="close"
    >
      <v-card>
        <v-card-title> </v-card-title>
        <br />
        <v-card-text>
          <v-row dene>
            <v-col cols="12" sm="12" md="12">
              <v-autocomplete
                :items="components_list"
                v-model="editForm"
                label="Chemin"
                item-text="code"
                item-value="code"
                :rules="[(v) => editForm.length > 0 || 'Chemin is   required']"
                outlined
                dense
                multiple
                chips
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" @click.stop="save_wmf" class="ma-1">
            Save
          </v-btn>
          <v-btn color="blue darken-1" @click.stop="close_wmf" class="ma-1">
            close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </v-form>
</template>
<script>
import CREATE_BARRIER from "../graphql/Dictionnary/Mutations/Failures/CREATE_BARRIER.gql";
import UPDATE_BARRIER from "../graphql/Dictionnary/Mutations/Failures/UPDATE_BARRIER.gql";
export default {
  components: {},
  props: {
    dialog: Boolean,
    components_list: Array,
    barrier: Array,
    id: Number,
    element: String,
  },
  data() {
    return {
      chemin: "",
      elements: [
        "Surface",
        "Subsurface",
        "Down head",
        "Surface/Subsurface",
        "Surface/Down head",
        "Subsurface/Down head",
        "Surface/Subsurface/Down head",
      ],
      editForm: [],
      element_chemin: "",
      snackbar: false,
      snackbar_timeout: 2000,
      snackbar_text: "",
      snackbar_color: "primary",
      selected_value: 0,
      selected_external: -1,
    };
  },
  watch: {},
  async mounted() {
    if (this.id < 0) {
      this.editForm = [];
      this.element_chemin = "";
    } else {
      this.editForm = Object.assign([], this.barrier);
      this.element_chemin = this.element;
    }
  },
  async created() {},
  methods: {
    barrierchange() {
      this.chemin = "";
      this.editForm.forEach((element) => {
        this.chemin += ";" + element;
      });
      this.chemin += ";";
    },
    close_wmf() {
      this.$emit("close");
    },
    async save_wmf() {
      this.barrierchange();
      let r;
      let isNew = false;
      let v = { id: null, chemin: this.chemin, element: this.element_chemin };
      let wf = { wmf: v };
      if (this.id >= 0) {
        v.id = this.id;
        isNew = false;
        r = await this.$maj(UPDATE_BARRIER, wf);
      } else {
        r = await this.$maj(CREATE_BARRIER, wf);
        isNew = true;
      }
      if (r.ok) {
        if (isNew) v.id = r.data.CREATE_BARRIER.id;
        v.isNew = isNew;
        this.$emit("close", v);
      }
    },
  },
};
</script>
